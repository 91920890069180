import React, { Component } from 'react';
import { client } from '../../../core/graphql/client';
import { CURRENT_USER } from '../../../core/graphql/quering/queries/auth';
import { UsersPermissionsMe } from '../../../core/models/interfaces';
import { IDataTableProps, DataTable } from './DataTable';



interface IState{
  isStudent:boolean
}
interface IProps {
  tabTitle: string;
  tables: {
    labelTab: string;
    idTab: string;
    icon?: string;
    table: IDataTableProps;
    addButton?: boolean;
    addButtonText?: string;
    addButtonTarget?: string;
  }[];
};

export class MultiTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isStudent: null
    }
  }

  componentDidMount() {
    client.query<{ currentUser: UsersPermissionsMe }>({ query: CURRENT_USER }).then(auth =>{
    //  const isProfesor = auth.data.currentUser?.role.name === "ROLE_PROFESOR";
    this.setState({
      ...this.state,
      isStudent: auth.data.currentUser?.role.name === "ROLE_STUDENT"
    })
    }
    ).catch(err =>
      console.log(err)
    )
  }

  render() {
    const tables = this.state.isStudent ? this.props.tables.filter(e=>e.idTab !== "events") : this.props.tables;
    return (
      <div className="card">
        <div className="card-header card-header-tabs card-header-primary">
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <span className="nav-tabs-title">{this.props.tabTitle}:</span>
              <ul className="nav nav-tabs" data-tabs="tabs">
                {tables.map((t, i) => (
                  <li className="nav-item" key={i}>
                    <a className={`nav-link ${i === 0 ? 'active' : ''}`} href={`#${t.idTab}`} data-toggle="tab">
                      <i className="material-icons">{t.icon}</i> {t.labelTab}
                      <div className="ripple-container"></div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content">
            {tables.map((t, i) => (
              <div className={`tab-pane ${i === 0 ? 'active show' : ''}`} id={t.idTab} key={i}>
                <DataTable {...t.table} addButton={t.addButton} addButtonTarget={t.addButtonTarget} addButtonText={t.addButtonText} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
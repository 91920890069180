import React, { Component, createRef } from 'react';
import { UserForm } from '../../form/UserForm';
import { DataTable, IDataTableProps } from './DataTable';

interface IProps extends IDataTableProps {
  setPerPage?: Function;
  onFilterData?: Function;
  roles?: any;
  showAddForm?: boolean;
  onAdd?: Function;
  listLabel?: string;
  addLabel?: string;
  loading?: boolean;
  addFormBeforeContent?: any;
  validate?: Function;
  currentPage?: number;
}

interface IState {
  adminOptions?: boolean;
  cols: any;
  pages: any[][];
  perPageOpts: number[];
  perPage: number;
  currentPage: number;
  count: number;
}

export class SimpleTable extends Component<IProps, IState> {
  tableTab = createRef<HTMLAnchorElement>();
  addFormTab = createRef<HTMLAnchorElement>();
  exportExcel = createRef<HTMLButtonElement>();

  constructor(props) {
    super(props);
    this.state = {
      adminOptions: true,
      cols: props.cols,
      pages: props.pages,
      currentPage: props.currentPage || 1,
      count: props.count || 0,
      perPage: props.perPage || 10,
      perPageOpts: [1, 5, 10, 15, 20]
    };
  }

  componentDidMount() {

    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {
      classes: 'pagination-select text-primary border-bottom',
      dropdownOptions: {
        constrainWidth: false
      }
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.propsToState(props);
  }

  propsToState(props: IProps) {
    const state: IState = {
      ...this.state,
      currentPage: props.currentPage || 1,
      cols: props.cols,
      pages: props.pages,
      count: props.count || 0,
      perPage: props.perPage || this.state.perPage
    };
    this.setState(state);
  }

  navigateTo(id) {
    if (this.props.navigationOnClick) {
      const { history, navigationBase } = this.props;
      history.push(`${navigationBase}/${id}`);
    }
  }

  onAdd(fields) {
    if (this.props.onAdd) {
      let data = {};
      fields.forEach(field => data[field?.name] = field.value);
      this.props.onAdd(data);
    }
  }

  viewTable() {
    this.tableTab.current.click();
  }

  viewAddForm() {
    if (this.props.showAddForm) {
      this.addFormTab.current.click();
    }
  }

  render() {
    const columnsWithoutId = this.state.cols.filter(col =>
      !['id', 'ID', 'NO.', 'No.'].includes(col.label?.toUpperCase?.())
    );

    return (
      <>
        <div className="card">
          <div className="card-header card-header-primary blue">
            <div className="float-left">
              <h4 className="card-title ">{this.props.title}</h4>
              <p className="card-category"> {this.props.subtitle}</p>
            </div>

            <div className="nav-tabs-navigation float-right">
              <div className="nav-tabs-wrapper">
                <ul className="nav nav-tabs" data-tabs="tabs">
                  <li className="nav-item">
                    <a ref={this.tableTab} className="nav-link active show" href="#list" data-toggle="tab">
                      <i className="material-icons">list</i> {this.props.listLabel || 'Listado'}
                      <div className="ripple-container"></div>
                      <div className="ripple-container"></div></a>
                  </li>
                  {this.props.showAddForm && <li className="nav-item">
                    <a ref={this.addFormTab} className="nav-link" href="#add" data-toggle="tab">
                      <i className="material-icons">add</i> {this.props.addLabel || 'Adicionar'}
                      <div className="ripple-container"></div>
                      <div className="ripple-container"></div></a>
                  </li>}
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane active show" id="list">
                <DataTable
                  onFilterData={this.props.onFilterData}
                  cols={columnsWithoutId}
                  pages={this.state.pages}
                  count={this.state.count}
                  roles={this.props?.roles}
                  perPage={this.state.perPage}
                  setPerPage={this.props?.setPerPage}
                  title={this.props.title}
                  subtitle={this.props.subtitle}
                  navigationOnClick={this.props.navigationOnClick}
                  navigationBase={this.props.navigationBase}
                  onEmptyPage={this.props.onEmptyPage}
                  onDelete={(id) => { this.props.onDelete && this.props.onDelete(id) }}
                  deleteModalText={this.props?.deleteModalText}
                  addons={this.props.addons}
                  report={this.props.report}
                  currentPage={this.state.currentPage}
                  adminOptions={this.state?.adminOptions}
                />
              </div>
              {this.props.showAddForm && (
                <div className="tab-pane" id="add">
                  {this.props.addFormBeforeContent}
                  <UserForm
                    validate={this.props.validate}
                    roles={this.props?.roles}
                    fields={this.props.fields}
                    title="Student Information"
                    onSubmit={(fields) => this.onAdd(fields)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
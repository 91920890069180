import { ApolloClient } from "@apollo/client";
import { IS_LOGGED_IN, CURRENT_USER } from "../quering";
import { environments } from "../../../environments";

export default (client: ApolloClient<any>) => {
  client.cache.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      isLoggedIn: !!localStorage.getItem(environments.secureToken)
    }
  });

  client.cache.writeQuery({
    query: CURRENT_USER,
    data: {
      currentUser: JSON.parse(localStorage.getItem(environments.userInfo))
    }
  })
}

import React from "react";
import { Shell } from "./features/shell";
import './index.css';
//import { Modal } from "./shared/modals/Modal";


function App() {
  return (

    <div className="App">
      <Shell></Shell>
    </div>
  );
}

export default App;
